<template lang="pug">
.Procedure
  .tapProcedure
  .position-relative
    .d-flex.align-items-center.justify-content-between.backButton(@click="BackCalculed")
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.ml-3
    .w-100.d-flex.justify-content-center.columnClass
      img(src="@/assets/mobileFSFB/logoFSFB.png" alt="logoFSFB").logoAuth.mx-auto
      h3.yourTurnLabelProcedure {{ selectedArea ? selectedArea.label : "" }}
    .parentList(v-if="parentList.length > 0" v-html="pageSubTramiteLabel")
    .childList.px-3(v-else v-html="pageTramiteLabel")
    h2.titleFSFBM.pl-3.pt-4(v-if="!pageTramiteLabel && !pageSubTramiteLabel") Cuéntanos, ¿qué necesitas?
    p.desc2.px-3(v-if="!pageTramiteLabel && !pageSubTramiteLabel")
      | Selecciona un tipo de trámite
      span.ml-0.d-block para continuar
    .pt-3.buttons-dk.text-start.mx-auto.text-center.d-flex.flex-wrap.justify-content-center
      .d-inline-block(v-for="act in actionToShow").mx-2
        button.arrowButton.p-1.w-100.my-2.text-center.button-without-styles(
          v-if="!!!act.hidden"
          :key="act.actionCode"
          :disabled="!!act.disabled"
          block
          @click="() => onSelectedThisProcedure(act)"
        )
          //- div(
          //-   v-html="selectIcon(act.label)"
          //- )
          img(:src="act.button_icon" :alt="act.label" width="47" height="49")
          span(v-html="act.label").my-2.w-100.text-center.d-block.fontButton
      div(style="width: 160px !important;")
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Procedure",

  data() {
    return {
      parentList: [],
      actionToShow: []
    };
  },

  created() {
    this.setLocalActions(this.menu);
  },

  computed: {
    ...mapState({
      menu: state => state.mobileFSFB.menu,
      currentUser: state => state.mobileFSFB.currentUser,
      sites: state => state.mobileFSFB.sites,
      selectedArea: state => state.mobileFSFB.selectedArea
    }),

    messages() {
      return this.sites.find(
        site => site.code == this.$route.params.code
      )?.customAreas[this.$route.query.area]?.messages;
    },

    pageTramiteLabel() {
      return this.sites.find(
        site => site.code == this.$route.params.code
      )?.customAreas[this.$route.query.area]
      ?.pageTramiteLabelMobile;
    },

    pageSubTramiteLabel() {
      return this.sites.find(
        site => site.code == this.$route.params.code
      )?.customAreas[this.$route.query.area]
      ?.pageSubTramiteLabelMobile;
    },

    padding() {
      return this.sites.find(
        site => site.code == this.$route.params.code
      )?.customAreas[this.$route.query.area]?.padding;
    }
  },

  methods: {
    ...mapActions({
      goBack: "mobileFSFB/goBack",
      setAction: "mobileFSFB/setAction",
      turnCall: "mobileFSFB/turnCall",
      getMenus: "mobileFSFB/getMenus",
      goToView: "mobileFSFB/goToView"
    }),

    setLocalActions(actions) {
      actions = JSON.parse(JSON.stringify(actions));
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].hidden && typeof actions[i].hidden === "object") {
          actions[i].hidden = actions[i].hidden.mobile;
        }
      }
      this.actionToShow = actions;
    },

    onSelectedThisProcedure(act) {
      if (act?.children) {
        this.parentList.push(this.actionToShow);
        return this.setLocalActions(act.children);
      }

      // check user age
      let userAge = moment.duration(
        moment().diff(moment(this.currentUser?.fecha_nacimiento))
      );
      act.userAge = userAge.asYears();

      this.setAction(act);
    },

    BackCalculed() {
      if (this.parentList.length) {
        this.setLocalActions(this.parentList.pop());
      } else this.goBack();
    },

    selectIcon(labelIcon) {
      const icons = {
        "Paciente con cita programada": `
          <svg width="47" height="49" viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.1979 30.8461C38.2001 33.7525 37.34 36.5942 35.7265 39.0116C34.113 41.4289 31.8185 43.3132 29.1336 44.426C26.4487 45.5387 23.494 45.8299 20.6435 45.2626C17.793 44.6953 15.1749 43.2951 13.1206 41.2392C11.0662 39.1832 9.66805 36.5641 9.10298 33.7131C8.5379 30.8622 8.83135 27.9077 9.94618 25.2237C11.061 22.5396 12.9471 20.2466 15.3657 18.635C17.7843 17.0233 20.6267 16.1654 23.533 16.1699C27.4234 16.173 31.1535 17.7205 33.9033 20.4725C36.6532 23.2245 38.1979 26.9557 38.1979 30.8461Z" fill="#89D4F4"/>
            <path d="M27.2253 10.3896C27.2279 12.052 26.7372 13.6778 25.8155 15.0612C24.8938 16.4447 23.5824 17.5237 22.0473 18.1616C20.5121 18.7995 18.8223 18.9677 17.1915 18.645C15.5608 18.3222 14.0624 17.523 12.886 16.3484C11.7096 15.1738 10.9081 13.6766 10.5829 12.0464C10.2576 10.4161 10.4233 8.72602 11.0588 7.18992C11.6944 5.65383 12.7714 4.34078 14.1534 3.41695C15.5355 2.49311 17.1605 2 18.8229 2C21.0491 2 23.1844 2.88348 24.7598 4.45647C26.3352 6.02946 27.2219 8.16337 27.2253 10.3896V10.3896Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.0064 35.5714C1.94445 33.3298 2.33247 31.0984 3.14752 29.0093C3.96257 26.9202 5.18813 25.0156 6.75175 23.4082C8.31538 21.8007 10.1854 20.523 12.2512 19.6506C14.317 18.7781 16.5368 18.3286 18.7793 18.3286C21.0217 18.3286 23.2415 18.7781 25.3073 19.6506C27.3731 20.523 29.2431 21.8007 30.8068 23.4082C32.3704 25.0156 33.5959 26.9202 34.411 29.0093C35.226 31.0984 35.6141 33.3298 35.5521 35.5714H2.0064Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.136 26.4194V29.2651" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.6421 26.4194V29.2651" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.4173 27.6187H42.3163C43.0256 27.6187 43.7058 27.9004 44.2073 28.402C44.7089 28.9035 44.9906 29.5837 44.9906 30.293V44.6407C44.9906 45.352 44.7081 46.0341 44.2051 46.5371C43.7022 47.04 43.0201 47.3226 42.3088 47.3226H27.4098C26.7006 47.3226 26.0203 47.0408 25.5188 46.5393C25.0172 46.0377 24.7355 45.3575 24.7355 44.6482V30.293C24.7355 29.9412 24.8049 29.5928 24.9398 29.2679C25.0746 28.9429 25.2723 28.6478 25.5214 28.3993C25.7705 28.1509 26.0662 27.9541 26.3916 27.8201C26.7169 27.6861 27.0655 27.6177 27.4173 27.6187V27.6187Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.7429 35.4341H45.0353" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Paciente con autorización previa": `
          <svg width="59" height="46" viewBox="0 0 59 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.6943 25.0737C48.6966 27.9801 47.8365 30.8218 46.223 33.2391C44.6094 35.6565 42.315 37.5408 39.63 38.6535C36.9451 39.7663 33.9904 40.0574 31.1399 39.4901C28.2895 38.9228 25.6714 37.5226 23.617 35.4667C21.5627 33.4108 20.1645 30.7916 19.5994 27.9407C19.0344 25.0898 19.3278 22.1353 20.4426 19.4512C21.5575 16.7671 23.4435 14.4742 25.8621 12.8625C28.2807 11.2508 31.1231 10.393 34.0295 10.3975C37.9199 10.4005 41.6499 11.9481 44.3998 14.7001C47.1496 17.4521 48.6943 21.1833 48.6943 25.0737Z" fill="#B5DC68"/>
            <path d="M27.0456 10.3298C27.0481 11.9803 26.561 13.5945 25.6458 14.9681C24.7307 16.3417 23.4286 17.413 21.9044 18.0464C20.3803 18.6798 18.7025 18.8468 17.0833 18.5263C15.4642 18.2059 13.9765 17.4123 12.8085 16.2461C11.6405 15.0799 10.8447 13.5934 10.5218 11.9748C10.1988 10.3561 10.3633 8.67807 10.9944 7.15293C11.6254 5.62779 12.6947 4.3241 14.0669 3.40685C15.4391 2.48959 17.0525 2 18.7031 2C20.9135 2 23.0335 2.87718 24.5977 4.43896C26.1618 6.00074 27.0422 8.11943 27.0456 10.3298V10.3298Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.00636 35.3323C1.94485 33.1066 2.3301 30.8912 3.13934 28.817C3.94859 26.7427 5.1654 24.8517 6.71789 23.2558C8.27037 21.6598 10.127 20.3912 12.1781 19.5249C14.2292 18.6587 16.4331 18.2124 18.6597 18.2124C20.8862 18.2124 23.0901 18.6587 25.1412 19.5249C27.1923 20.3912 29.0489 21.6598 30.6014 23.2558C32.1539 24.8517 33.3707 26.7427 34.18 28.817C34.9892 30.8912 35.3745 33.1066 35.313 35.3323H2.00636Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.2018 33.0347C57.1974 35.223 56.5444 37.3609 55.3255 39.1782C54.1065 40.9956 52.3763 42.4108 50.3533 43.2452C48.3303 44.0795 46.1054 44.2956 43.9597 43.8659C41.814 43.4363 39.8438 42.3803 38.298 40.8314C36.7522 39.2825 35.7002 37.3102 35.2749 35.1636C34.8496 33.017 35.0701 30.7926 35.9085 28.7713C36.747 26.75 38.1657 25.0226 39.9855 23.8073C41.8053 22.592 43.9445 21.9434 46.1328 21.9434C47.5883 21.9434 49.0295 22.2304 50.3739 22.7881C51.7183 23.3457 52.9396 24.1631 53.9677 25.1933C54.9958 26.2235 55.8107 27.4464 56.3657 28.7919C56.9206 30.1374 57.2048 31.5792 57.2018 33.0347V33.0347Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M42.4157 32.3193L45.3729 35.2765" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.8428 30.8003L45.3735 35.277" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Asignación de citas ": `
          <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.0702 25.0737C46.0724 27.9801 45.2123 30.8218 43.5988 33.2391C41.9853 35.6565 39.6908 37.5408 37.0059 38.6535C34.321 39.7663 31.3663 40.0574 28.5158 39.4901C25.6653 38.9228 23.0472 37.5226 20.9929 35.4667C18.9386 33.4108 17.5404 30.7916 16.9753 27.9407C16.4102 25.0898 16.7037 22.1353 17.8185 19.4512C18.9333 16.7671 20.8194 14.4742 23.238 12.8625C25.6566 11.2508 28.499 10.393 31.4053 10.3975C35.2957 10.4005 39.0258 11.9481 41.7756 14.7001C44.5255 17.4521 46.0702 21.1833 46.0702 25.0737Z" fill="#E16E5F"/>
            <path d="M9.67963 2V6.04646" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.196 2V6.04646" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.81343 3.70558H26.9991C28.0077 3.70558 28.975 4.10623 29.6881 4.8194C30.4013 5.53257 30.8019 6.49983 30.8019 7.50841V27.9102C30.8019 28.9216 30.4002 29.8916 29.685 30.6067C28.9699 31.3219 27.9999 31.7236 26.9885 31.7236H5.80283C4.79425 31.7236 3.82699 31.323 3.11382 30.6098C2.40065 29.8967 2 28.9294 2 27.9208V7.50841C2 7.00812 2.09872 6.51275 2.29049 6.05068C2.48226 5.58861 2.76332 5.16891 3.11757 4.81565C3.47182 4.46239 3.89229 4.18249 4.35489 3.992C4.8175 3.80152 5.31314 3.70419 5.81343 3.70558V3.70558Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.01056 10.8555H30.8655" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Entrega de resultados": `
          <svg width="47" height="53" viewBox="0 0 47 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.3849 37.3953C46.3872 40.3224 45.521 43.1845 43.8959 45.6191C42.2708 48.0537 39.96 49.9514 37.2559 51.0721C34.5518 52.1928 31.576 52.4861 28.7051 51.9147C25.8343 51.3434 23.1975 49.9332 21.1285 47.8626C19.0595 45.792 17.6513 43.1541 17.0822 40.2828C16.5131 37.4115 16.8086 34.4359 17.9314 31.7327C19.0542 29.0294 20.9538 26.7201 23.3896 25.0969C25.8255 23.4737 28.6882 22.6097 31.6153 22.6143C35.5335 22.6173 39.2902 24.1759 42.0597 26.9476C44.8292 29.7192 46.3849 33.4771 46.3849 37.3953Z" fill="#6BBBC5"/>
            <path d="M7.97866 9.07764H6.39436C5.23088 9.07763 4.1149 9.53904 3.29111 10.3607C2.46733 11.1823 2.003 12.297 1.99994 13.4605V37.2598C2.003 38.4233 2.46733 39.5381 3.29111 40.3597C4.1149 41.1813 5.23088 41.6427 6.39436 41.6427H27.0713C27.6498 41.6473 28.2235 41.5373 28.7592 41.3191C29.295 41.1009 29.7822 40.7787 30.1929 40.3713C30.6036 39.9638 30.9295 39.4792 31.152 38.9451C31.3744 38.4111 31.4889 37.8383 31.4889 37.2598V13.4605C31.4889 12.2981 31.0271 11.1833 30.2052 10.3613C29.3832 9.53938 28.2684 9.07764 27.106 9.07764H25.5448" stroke="#001698" stroke-width="2.11429" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5143 5.96636H24.0877C24.2795 5.96481 24.4697 6.00145 24.6471 6.07414C24.8246 6.14682 24.9858 6.2541 25.1215 6.38971C25.2571 6.52532 25.3643 6.68655 25.437 6.86403C25.5097 7.04151 25.5463 7.23169 25.5448 7.42347V10.7193C25.5463 10.9116 25.5098 11.1023 25.4372 11.2804C25.3647 11.4586 25.2576 11.6205 25.1222 11.7571C24.9867 11.8936 24.8256 12.002 24.648 12.0759C24.4705 12.1499 24.28 12.188 24.0877 12.188H9.44733C9.05781 12.188 8.68423 12.0332 8.4088 11.7578C8.13338 11.4824 7.97864 11.1088 7.97864 10.7193V7.42347C7.97863 7.23114 8.0167 7.04069 8.09065 6.86315C8.1646 6.6856 8.27298 6.52446 8.40951 6.38901C8.54604 6.25355 8.70804 6.14647 8.88616 6.07392C9.06428 6.00138 9.25501 5.96483 9.44733 5.96636H13.0091" stroke="#001698" stroke-width="2.11429" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.0091 5.96679C12.9808 5.45647 13.0569 4.94571 13.2326 4.46578C13.4084 3.98585 13.6802 3.54682 14.0315 3.17552C14.3827 2.80423 14.806 2.50849 15.2754 2.30636C15.7449 2.10424 16.2506 2 16.7617 2C17.2729 2 17.7786 2.10424 18.248 2.30636C18.7175 2.50849 19.1408 2.80423 19.492 3.17552C19.8433 3.54682 20.1151 3.98585 20.2909 4.46578C20.4667 4.94571 20.5427 5.45647 20.5144 5.96679" stroke="#001698" stroke-width="2.11429" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.0291 21.3245C23.5984 20.911 23.0245 20.6802 22.4275 20.6802C21.8304 20.6802 21.2565 20.911 20.8258 21.3245L15.3212 26.8522L13.3553 24.8863C13.1645 24.6029 12.9132 24.3653 12.6197 24.1905C12.3261 24.0158 11.9974 23.9082 11.6573 23.8756C11.3172 23.843 10.9741 23.8862 10.6527 24.002C10.3313 24.1178 10.0395 24.3034 9.79832 24.5455C9.55717 24.7875 9.37263 25.0799 9.25797 25.4018C9.14332 25.7236 9.10138 26.0669 9.1352 26.4068C9.16901 26.7468 9.27772 27.0751 9.45352 27.368C9.62933 27.661 9.86787 27.9114 10.152 28.1012L13.7138 31.663C14.145 32.0798 14.7214 32.3129 15.3212 32.3129C15.921 32.3129 16.4974 32.0798 16.9286 31.663L24.0291 24.5625C24.4529 24.1301 24.6903 23.5489 24.6903 22.9435C24.6903 22.3381 24.4529 21.7568 24.0291 21.3245Z" stroke="#001698" stroke-width="2.11429" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Solicitud de medio de contraste (TAC)": `
          <svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.1269 31.8964C49.1291 34.8028 48.269 37.6445 46.6555 40.0619C45.042 42.4792 42.7475 44.3635 40.0626 45.4763C37.3777 46.589 34.423 46.8802 31.5725 46.3129C28.722 45.7456 26.1039 44.3454 24.0496 42.2895C21.9953 40.2335 20.5971 37.6143 20.032 34.7634C19.4669 31.9125 19.7604 28.958 20.8752 26.274C21.99 23.5899 23.8761 21.2969 26.2947 19.6853C28.7133 18.0736 31.5557 17.2157 34.462 17.2202C38.3524 17.2232 42.0825 18.7708 44.8323 21.5228C47.5822 24.2748 49.1269 28.006 49.1269 31.8964Z" fill="#F2E462"/>
            <path d="M29.3876 37.1393C29.1689 37.1433 28.9519 37.0988 28.7509 37.0088L19.5076 32.8804L10.2757 37.0088C10.0177 37.1257 9.73486 37.1696 9.45547 37.1363C9.17609 37.103 8.91001 36.9936 8.68396 36.819C8.45553 36.649 8.27425 36.419 8.15845 36.1524C8.04264 35.8857 7.99645 35.5918 8.02455 35.3005L8.92274 24.8608L2.31714 16.9598C2.1293 16.7411 1.99991 16.4746 1.94204 16.1873C1.88416 15.8999 1.89982 15.6017 1.98742 15.3226C2.07234 15.051 2.22073 14.8057 2.41922 14.609C2.61771 14.4122 2.86006 14.2701 3.12436 14.1956L12.9247 11.8229L18.0751 2.81859C18.2187 2.56861 18.422 2.36177 18.6651 2.21829C18.9082 2.07481 19.1828 1.9996 19.4621 2.00002V2.00002C19.7432 1.99871 20.0197 2.07345 20.2648 2.21692C20.5099 2.36038 20.7152 2.56767 20.8606 2.81859L26.0109 11.8229L35.7999 14.1956C36.0752 14.2581 36.33 14.3947 36.539 14.5919C36.748 14.789 36.9039 15.0397 36.9913 15.3192C37.0787 15.5987 37.0944 15.8971 37.037 16.1849C36.9796 16.4727 36.851 16.7399 36.664 16.9598L30.1039 24.8845L30.9793 35.0514C30.9974 35.1931 30.9974 35.3367 30.9793 35.4785C30.9793 35.7007 30.9372 35.9207 30.8553 36.1259C30.7735 36.3311 30.6536 36.5173 30.5024 36.6739C30.3513 36.8305 30.172 36.9543 29.9748 37.0382C29.7776 37.1222 29.5665 37.1646 29.3535 37.1631L29.3876 37.1393Z" stroke="#001698" stroke-width="2.09929" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `
      };

      return icons[labelIcon] || "";
    }
  }
};
</script>

<style lang="scss">
.Procedure {
  height: 100vh;
  overflow: auto;
  background-image: url(../../assets/mobileFSFB/oldSenora.png);
  background-position: center;
  background-size: cover;
}

// .tapProcedure {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(40, 54, 69, 0.6);
// }

.desc2 {
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-white);
}

.backButton {
  padding-top: 21px;
}

.arrowButton {
  width: 148px !important;
  height: 142.75px;
  background: #ffffff;
  box-shadow: 0px 0px 5.77305px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.arrowButton .svgIcon {
  width: 50px;
}

.arrowButton span {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #788591;
}

.yourTurnLabelProcedure {
  font-family: var(--font-secondary);
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  padding-top: 26.12px;
  margin-bottom: 12.12px;
}

.d-inline-block {
  display: inline-block;
}

.columnClass {
  flex-direction: column;
  z-index: 9;
  margin-bottom: 5%;
}

.columnClass h3 {
  z-index: 9;
}

.logoAuth {
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}

.childList h3 {
  color: var(--color-white);
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
}

.childList p {
  text-align: start;
  color: var(--color-white);
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0 !important;
}
</style>
